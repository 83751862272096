import { useEffect, useState } from 'react'
import Loader from 'react-loaders'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { useRef } from 'react'
import AnimatedLetters from '../AnimatedLetters'
import './index.scss'
import { useForm } from '@formspree/react'

const Contact = () => {
  const form = useRef()

  const [letterClass, setLetterClass] = useState('text-animate')
  const [state, handleSubmit] = useForm('xgejaykk')
  if (state.succeeded) {
    return (
      <div style={styles.heading}>
        <h1>
          <AnimatedLetters
            letterClass={letterClass}
            strArray={[
              'W',
              'i',
              'l',
              'l',
              ' ',
              'g',
              'e',
              't ',
              '',
              'b',
              'a',
              'c',
              'k',
              ' ',
              't',
              'o',
              '',
              'y',
              'o',
              'u',
              '',
              ':',
              ')',
            ]}
            idx={15}
          />
        </h1>
      </div>
    )
  }

  const position = [18.4972936, 73.9188192]

  //   useEffect(() => {
  //     setTimeout(() => {
  //      setLetterClass('text-animate-hover')
  //    }, 3000)
  //  }, [])

  return (
    <>
      <div className="container contact-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['C', 'o', 'n', 't', 'a', 'c', 't', ' ', 'm', 'e']}
              idx={15}
            />
          </h1>
          <p>
            I am interested in freelance opportunities - especially on ambitious
            or large projects. However, if you have any other requests or
            questions, don't hesitate to contact me using below form either.
          </p>
          <div className="contact-form">
            <form
              //  ref={form}
              onSubmit={handleSubmit}
            >
              <ul>
                <li className="half">
                  <input placeholder="Name" type="text" name="name" required />
                </li>
                <li className="half">
                  <input
                    placeholder="Email"
                    type="email"
                    name="email"
                    required
                  />
                </li>

                <li>
                  <textarea
                    placeholder="Message"
                    name="message"
                    required
                  ></textarea>
                </li>
                <li>
                  <input type="submit" className="flat-button" value="SEND" />
                </li>
              </ul>
            </form>
          </div>
        </div>
        <div className="info-map">
          Aquib Yazdani, Pune,
          <br />
          India <br />
          <br />
          <span>yazdaniaquib2@gmail.com</span>
        </div>
        <div className="map-wrap">
          <MapContainer center={position} zoom={13} scrollWheelZoom={false}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={position}>
              <Popup>
                Aquib lives here, <br /> come over for a cup of coffee :)
              </Popup>
            </Marker>
          </MapContainer>
        </div>
      </div>
      <Loader type="pacman" />
    </>
  )
}
const styles = {
  heading: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    marginBottom: '20px',
    opacity: '1',
    color: 'azure',
  },
}

export default Contact
