import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
// import './index.scss'

const NotFound = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  const nameArray = [' ', 'N', 'o', 't', '', 'F', 'o', 'u', 'n', 'd']
  const jobArray = [
    'w',
    'e',
    'b',
    ' ',
    'd',
    'e',
    'v',
    'e',
    'l',
    'o',
    'p',
    'e',
    'r',
    '.',
  ]

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 4000)
  }, [])

  return (
    <>
      <div className="container home-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={nameArray}
              idx={15}
            />
          </h1>
          <h2>Front End Developer / JavaScript Expert / Tech Explorer</h2>
          <br />
          <Link
            to="/"
            className="flat-button"
            style={{
              display: 'initial',
            }}
          >
            Home
          </Link>
        </div>
      </div>

      <Loader type="pacman" />
    </>
  )
}

export default NotFound
