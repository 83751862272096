import React, { useEffect, useState } from 'react'
import { Tilt } from 'react-tilt'
import { motion } from 'framer-motion'
import { fadeIn } from '../../utils/motion'

import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import './index.scss'
import current from '../../assets/images/carrent.png'
import jobit from '../../assets/images/jobit.png'
import picme from '../../assets/images/picofme.png'
import shoppe from '../../assets/images/shoppe.png'
import travel from '../../assets/images/mmtravel.png'

function Work() {
  const projects = [
    // {
    //   name: 'Memorable mumbai',
    //   description:
    //     'A collection of many travel packages in mumbai to experience with.',
    //   tags: [
    //     {
    //       name: 'reactJs',
    //       color: 'blue-text-gradient',
    //     },
    //     {
    //       name: 'restapi',
    //       color: 'green-text-gradient',
    //     },
    //     {
    //       name: 'css',
    //       color: 'pink-text-gradient',
    //     },
    //     {
    //       name: 'restapi',
    //       color: 'green-text-gradient',
    //     },
    //   ],
    //   image: travel,
    //   source_code_link: 'https://memorablemumbai.com/',
    // },
    // {
    //   name: 'CMS Post Creation',
    //   description:
    //     'Our CMS (Content Management System) app is designed to streamline the process of creating and managing content for your website. With an intuitive interface and powerful features.',
    //   tags: [
    //     {
    //       name: 'reactJs',
    //       color: 'blue-text-gradient',
    //     },
    //     {
    //       name: 'restapi',
    //       color: 'green-text-gradient',
    //     },

    //     {
    //       name: 'expressJs',
    //       color: 'green-text-gradient',
    //     },
    //     {
    //       name: 'nodeJs',
    //       color: 'pink-text-gradient',
    //     },
    //   ],
    //   image: jobit,
    //   source_code_link: 'https://cms.aquibyazdani.com/',
    // },
    {
      name: 'Spotify Clone',
      description:
        'A clone of spotify using react js. Features including play pause next.',
      tags: [
        {
          name: 'reactjS',
          color: 'blue-text-gradient',
        },
        {
          name: 'reactstrap',
          color: 'green-text-gradient',
        },
        {
          name: 'css3',
          color: 'pink-text-gradient',
        },
      ],
      image: current,
      source_code_link: 'https://spotify-yazdani.netlify.app/',
    },

    {
      name: 'Shopping Website',
      description: 'A minimalist shopping website with all the features.',
      tags: [
        {
          name: 'reactJs',
          color: 'blue-text-gradient',
        },
        {
          name: 'restapi',
          color: 'green-text-gradient',
        },
        {
          name: 'css',
          color: 'pink-text-gradient',
        },
      ],
      image: shoppe,
      source_code_link: 'https://shoppe-aquib-yazdani.netlify.app/',
    },
  ]
  const ProjectCard = ({
    index,
    name,
    description,
    tags,
    image,
    source_code_link,
  }) => {
    return (
      <motion.div variants={fadeIn('up', 'spring', index * 0.5, 0.75)}>
        <Tilt
          options={{
            max: 45,
            scale: 1,
            speed: 450,
          }}
          className="bg-tertiary tilt_wrapper p-5 rounded-2xl sm:w-[360px] w-full"
        >
          <div className="me_logo_container">
            <img
              width={'25px'}
              src={picme}
              alt="source code"
              className="w-1/2 h-1/2 object-contain"
            />
          </div>
          <div
            className="relative w-full h-[230px]"
            onClick={() => window.open(source_code_link)}
          >
            <img
              src={image}
              alt="project_image"
              width={'100%'}
              className="w-full h-full object-cover rounded-2xl"
            />
          </div>

          <div className="mt-5 relative-pos">
            <h3 className="header_card">{name}</h3>
            <p className="header_card_para mt-2">{description}</p>
          </div>

          <div className="hash_container mt-4 flex flex-wrap gap-2">
            {tags.map((tag, i) => (
              <p key={`${name}-${tag.name}`} className={'logo' + i}>
                #{tag.name}
              </p>
            ))}
          </div>
        </Tilt>
      </motion.div>
    )
  }
  const [letterClass, setLetterClass] = useState('text-animate')

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)
  }, [])
  return (
    <>
      <div className="container portfolio-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['M', 'y', '', 'W', 'o', ' r', 'k']}
              idx={15}
            />
          </h1>
          {/* <p>
            I'm a very ambitious front-end developer looking for a role in an
            established IT company with the opportunity to work with the latest
            technologies on challenging and diverse projects.
          </p>
          <p align="LEFT">
            I'm quiet confident, naturally curious, and perpetually working on
            improving my chops one design problem at a time.
          </p>
          <p>
            If I need to define myself in one sentence that would be a techie person, a sports fanatic,
            photography enthusiast, and tech-obsessed!!!
          </p> */}
        </div>
        <div className="stage-container">
          <div className="projrct_card_container">
            {projects.map((project, index) => (
              <ProjectCard
                key={`project-${index}`}
                index={index}
                {...project}
              />
            ))}
          </div>
        </div>
      </div>
      <Loader type="pacman" />
    </>
  )
}

export default Work
